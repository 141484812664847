import * as React from "react"
import { Link } from "gatsby"
import { useState, useEffect } from "react"
import { CheckmarkSharp } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContinueCheckout from '../components/ContinueCheckout.js'
import EndCheckout from '../components/EndCheckout.js'
import Progress from '../components/Progress.js'
import CountryCode from '../components/CountryCode.js'
import { navigate } from "gatsby"
import { useContext } from 'react'
import { CheckoutContext } from "../context/CheckoutContext"
import Auth from '../components/Auth'
import Footer from '../components/Footer.js'


const PhoneField = (props) => {
  const [countryCode, setCountryCode] = useState(371);
  const [phone, setPhone] = useState('');
  const phoneField = React.useRef(null);

  const _setPhone = (e) => {
    // console.log(e.target.value)
    setPhone(e.target.value)
  }
  const _setCountryCode = (e) => {
    // console.log(e.target.value)
    setCountryCode(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(phone ? `${countryCode}${phone}` : null)
  }

  useEffect(() => {
    phoneField.current.focus()
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit} class="is-max-mobile">
        <label class="label">Tavs mobilā telefona numurs</label>

        <div class="field has-addons">
          <div class="control  ">
            <span class="select ">
              <CountryCode value={countryCode} handleChange={_setCountryCode} />
              {/* <select onChange={_setCountryCode} value={countryCode ? countryCode : ''}>
                <option value="371">+371</option>
                <option value="372">+372</option>
                <option value="373">+373</option>
              </select> */}
            </span>
          </div>
          <div class={props.isLoading ? "control is-expanded is-loading " : "control is-expanded "}>
            <input ref={phoneField} class="input " required type="tel" placeholder="Telefona numurs" value={phone ? phone : ''} onChange={_setPhone} />
          </div>
          <div class="control is-hidden ">

            <button type="submit" ref={props.phoneFormRef} class={props.isLoading ? "button is-loading " : "button "}>
              Apstiprināt
            </button>
          </div>
        </div>
      </form>


      <p class="mt-2">Ar šo telefona numuru varēsi pieslēgties Heyday klienta profilam.</p>
      <p class="mt-2">Tajā varēsi pārvaldīt savu pirkumu un mainīt vai atcelt abonementu.</p>
    </>
  )
}

const CodeField = (props) => {
  const context = useContext(CheckoutContext)

  const codeForm = React.useRef(null);
  const codeField = React.useRef(null);
  const [code, setCode] = useState(null);

  const _setCode = (e) => {
    setCode(e.target.value)
  }

  const _onSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(code)
  }

  useEffect(() => {
    if (code) {
      if (code.length == 6) {
        codeForm.current.reportValidity()
        props.onSubmit(code)
      }
    }
  }, [code]);

  useEffect(() => {
    codeField.current.focus()
  }, [])

  return (
    <>

      <form onSubmit={_onSubmit} ref={codeForm} class="is-max-mobile">
        <label class="label">Apstiprinājuma kods</label>

        <div class="field has-addons">
          <div class={props.isLoading ? "is-loading is-large control is-expanded" : "control is-large is-expanded"}>
            <input ref={codeField} class="input is-large" value={code ? code : ''} required type="text" autoComplete="one-time-code" inputMode="numeric" pattern="[0-9]*" placeholder="000000" minLength="6" maxLength="6" onChange={_setCode} />
          </div>
          <div class="control is-hidden">
            <button ref={props.phoneFormRef} type="submit" class={props.isLoading ? "button is-loading is-medium" : "button is-medium"}>
              Apstiprināt
          </button>
          </div>
        </div>
        <p>Uz numuru +{context.phone} tūlīt saņemsi 6 ciparu kodu. Ievadi to laukā. </p>
        {/* <Link to="" class="">Mainīt numuru</Link> */}
        <p>
        </p>
      </form>

    </>
  )
}


const PhoneRegister = (props) => {
  const context = useContext(CheckoutContext)

  const [error, setError] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [phone, setPhone] = useState(null);


  const _handleCloseMessage = (e) => {
    e.preventDefault();
    setError(null)
  }


  const registerPhone = (phone) => {

    setIsLoading(true);

    fetch(`${process.env.GATSBY_API_BASE}/verif/phone/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ phone_number: `+${phone}` })
    })
      .then(res => res.json())
      .then(
        (result) => {

          if (result.session_token) {
            setToken(result.session_token);
            setPhone(`${phone}`);
            setError(false);

            // put in context

            context.setDeviceToken(result.session_token)
            context.setPhone(`${phone}`)

          } else {
            setError("Lūdzu ievadi pareizu numuru, piemēram, 20002000.")
          }
          setIsLoading(false);

        },
        (error) => {
          setIsLoading(false);
          setError(error);
        }
      )

  }

  const verifyCode = (code) => {
    // console.log('verify', code)



    setIsLoading(true);

    fetch(`${process.env.GATSBY_API_BASE}/verif/phone/verify_and_register`, { // TODO: register
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phone_number: `+${context.phone}`,
        session_token: context.deviceToken,
        security_code: code
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          // console.log('ok', result)
          if (result.non_field_errors) {
            setError("Ievadītais kods netika pieņemts. Pārliecinies, ka ievadīji to pareizi.")
          }
          if (!result.non_field_errors && !result.token) {
            setError("Neizdevās izveidot klienta profilu. Lūdzu, sazinies ar mūsu klientu atbalastu.")
          }
          if (result.token) {
            // process ok
            context.setSessionToken(result.token)
            setError(false);
            navigate("/delivery")
          }
          setIsLoading(false);

        },
        (error) => {
          setIsLoading(false);
          setError(error);
        }
      )



  }

  return (
    <>

      {error ? <article class="message is-warning">
        <div class="message-header">
          <p>Kļūda</p>
          <button class="delete" aria-label="delete" onClick={_handleCloseMessage}></button>
        </div>
        <div class="message-body">
          {error}
        </div>
      </article> : null}
      <div class="box">

        {!context.deviceToken ? <PhoneField phoneFormRef={props.phoneFormRef} onSubmit={registerPhone} isLoading={isLoading} /> : <CodeField phoneFormRef={props.phoneFormRef} phone={phone} onSubmit={verifyCode} isLoading={isLoading} />}
        <p class="mt-3"><button class="button is-primary is-fullwidth is-rounded is-medium" onClick={props.validate}>Turpināt</button></p>
      </div>

    </>
  )
}

// markup
const Delivery = () => {
  const [error, setError] = useState(false);
  const phoneFormRef = React.useRef(null);

  const _handleCloseMessage = (e) => {
    e.preventDefault();
    setError(null)
  }

  const context = useContext(CheckoutContext)

  useEffect(() => {
    if(context.sessionToken) {
      navigate("/delivery")
    }
  }, [])


  const validateContinuing = (e) => {
    if (context.sessionToken) {
      navigate("/delivery")

    } else {
      // try submitting the form
      // console.log('form', phoneFormRef.current)
      // phoneFormRef.current.dispatchEvent(new Event('submit', { cancelable: true }))

      // if (phoneFormRef.current) {
      //   if (typeof phoneFormRef.current.requestSubmit === 'function') {
      //     phoneFormRef.current.requestSubmit();
      //   } else {
      //     phoneFormRef.current.dispatchEvent(new Event('submit', {cancelable: true}));
      //   }
      // }
      // phoneFormRef.current.requestSubmit();
      // 
      phoneFormRef.current.click();


    // if (phoneFormRef.current.requestSubmit) {
    //   alert('submit  avaialbe')
    //     phoneFormRef.current.requestSubmit()
    // }
    // //If not, perform constraint validation and call submit function directly
    // else {
    //   alert('submit not avaialbe')
    //     if (phoneFormRef.current.reportValidity()) {
    //       phoneFormRef.current.requestSubmit()
    //       return
    //     }
    // }
    // phoneFormRef.current.requestSubmit()

      // setError('Lūdzu pabeidz kontaktinformācijas norādīšanu pirms turpini')
    }
  }

  return (
    <main class=" checkout checkout__keyboard">
      <section class="section">

        <div className="container">

        <Helmet title="Heyday ziedu piegāde" defer={false} />
        <Auth to="/subscribe" />


        <div class="columns is-mobile">
          <div class="column pt-0">
            <Progress value={40} />
          </div>
          <div class="column is-one-third pt-0">
            <EndCheckout />
          </div>
        </div>


        <h1 class="title is-1">Reģistrācija</h1>

        <div className="checkout-col">

        {error ? <article class="message is-warning">
          <div class="message-header">
            <p>{error}</p>
            <button class="delete" aria-label="delete" onClick={_handleCloseMessage}></button>

          </div>
        </article> : null}


        {context.sessionToken ? <article class="message is-success">
          <div class="message-header">
            <p>Klienta kontaktinformācija ir izveidots</p>
          </div>
          <div class="message-body content">
            <p>
            Tavs klienta profis jau ir izveidots. Tālāk vari turpināt uz maksājumu. Ja nepieciešams, klikšķini Iziet lapas augšpusē.
            </p>
            <p>
              <Link class="button is-rounded" to="/delivery">Turpināt</Link>
            </p>
        </div>
        </article> : <PhoneRegister phoneFormRef={phoneFormRef} validate={validateContinuing} />}

        </div>

        {/* <div class="content">

        <div class="columns is-mobile has-text-centered mt-5">
            <div class="column">Apturi vai maini piegādi, kad vien vajadzīgs</div>
            <div class="column">Droša apmaksa</div>
        </div>

      </div> */}

        {/* <p>

        {process.env.NODE_ENV === "development" ? (
          <>
            Dev
          </>
        ) : null}
        <br />

        <Link to="/">Home</Link>.
      </p> */}

      </div>
      </section>


      {/* <ContinueCheckout continue={validateContinuing} /> */}

      <ContinueCheckout read />


    </main>
  )
}

export default Delivery
